import { createTransformer, replaceRecursive } from ".";

/**
 * Transforms null to undefined.
 */
export const nullToUndefinedTransformer = createTransformer((response) => {
  return replaceRecursive(response, (key, value) => {
    if (value === null) {
      return undefined;
    }

    return value;
  });
});
