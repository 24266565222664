import { z } from "zod";

import { api } from "@/api";
import { type User, userSchema } from "@/types";

export function login(email: string, password: string) {
  return api.post<{ authToken: string; user: User }>("/v2/login", {
    body: { email, password },
    schema: z
      .object({
        data: userSchema.extend({
          authToken: z.string(),
        }),
      })
      .transform((response) => {
        const { authToken, ...user } = response.data;

        return { authToken, user };
      }),
  });
}

export function resetPassword(email: string) {
  return api.post("/v2/reset-password", {
    body: { email },
  });
}
