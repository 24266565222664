import { z } from "zod";

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

export const userSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
});
