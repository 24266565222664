import type { NavigationGuard } from "vue-router";

import { useUserStore } from "@/stores";

export const authGuard: NavigationGuard = (to) => {
  const { requiresAuth = true, redirectsAuth = false } = to.meta;
  const { isAuthenticated } = useUserStore();

  if (requiresAuth && !isAuthenticated) {
    return {
      name: "login",
      replace: true,
    };
  }

  if (redirectsAuth && isAuthenticated) {
    return {
      name: "home",
      replace: true,
    };
  }
};
