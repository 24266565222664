import { isPlatform } from "@ionic/vue";
import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
} from "@ionic/vue-router";
import type { RouteLocationRaw } from "vue-router";

import { authGuard, createTitleGuard } from "./guards";

/**
 * This is a hack to prevent native page swipe gesture on iOS PWA.
 * If there's no page history to swipe, what are you going to do, Apple...
 */
function createHistory(base?: string) {
  if (isPlatform("ios") && isPlatform("pwa")) {
    return createMemoryHistory(base);
  }

  return createWebHistory(base);
}

export const router = createRouter({
  history: createHistory(import.meta.env.BASE_URL),
  strict: true,
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/tabs",
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginPage.vue"),
      meta: {
        title: "Log in",
        requiresAuth: false,
        redirectsAuth: true,
      },
    },
    {
      path: "/tabs",
      component: () => import("@/views/TabsPage.vue"),
      children: [
        {
          path: "",
          name: "tabs",
          redirect: "/tabs/library",
        },
        {
          path: "library",
          name: "library",
          component: () => import("@/views/LibraryPage.vue"),
          meta: {
            title: "Audiobooks",
          },
        },
        {
          path: "settings",
          name: "settings",
          component: () => import("@/views/SettingsPage.vue"),
          meta: {
            title: "Settings",
          },
        },
      ],
    },
    {
      path: "/:notFound(.*)",
      redirect: "/",
    },
  ],
});

export function resolveRoute(route: RouteLocationRaw) {
  return router.resolve(route).href;
}

router.beforeEach(createTitleGuard());
router.beforeEach(authGuard);
