import { type PiniaPlugin, type Store } from "pinia";
import { clone } from "remeda";

const stores = new Set<Store>();

export const resetStoresPlugin: PiniaPlugin = ({ store }) => {
  const initialState = clone(store.$state);

  store.$reset = () => {
    try {
      store.$patch((state) => Object.assign(state, initialState));
    } catch (error) {
      if (error instanceof TypeError) {
        return;
      }

      throw error;
    }
  };

  stores.add(store);
};

export async function resetStores() {
  await Promise.allSettled(Array.from(stores).map((store) => store.$reset()));
}
