import {
  createApi,
  dateTransformer,
  isRequestError,
  isResponseError,
  isUnauthorizedResponseError,
  isUnexpectedResponseError,
  isValidationFailedResponseError,
  nullToUndefinedTransformer,
  snakeCaseRequestMiddleware,
} from "@packages/api";
import { createErrorHandler } from "@packages/error-handler";

import { useToasts } from "@/composables";

import { authMiddleware } from "./auth.middleware";
import { camelCaseTransformer } from "./camel-case.transformer";

export const api = createApi({
  baseUrl: import.meta.env.PUBLIC_API_BASE_URL,
  middlewares: [authMiddleware, snakeCaseRequestMiddleware()],
  transformers: [
    camelCaseTransformer(),
    nullToUndefinedTransformer,
    dateTransformer((key) => ["created", "modified"].includes(key)),
  ],
});

export const errorHandler = createErrorHandler((error) => {
  const toasts = useToasts();

  if (isRequestError(error)) {
    toasts.addError(
      "Kunne ikke oprette forbindelse til serveren. Tjek din internetforbindelse, eller prøv igen senere.",
    );
    return;
  }

  if (isUnauthorizedResponseError(error)) {
    toasts.addError("Du har ikke rettigheder til at udføre denne handling.");
    return;
  }

  if (isValidationFailedResponseError(error)) {
    toasts.addError("Kunne ikke behandle din forespørgsel. Prøv igen senere.");
    return;
  }

  if (isUnexpectedResponseError(error)) {
    toasts.addError("Der opstod en uventet fejl. Prøv igen senere.");
    return;
  }

  if (isResponseError(error)) {
    toasts.addError(error.message);
    return;
  }
});
