import { computed, ref } from "vue";

const deferredPromptEvent = ref<BeforeInstallPromptEvent>();

window.addEventListener("beforeinstallprompt", (event) => {
  event.preventDefault();
  deferredPromptEvent.value = event;
});

export function usePwaInstall() {
  const isInstalledMediaQuery = window.matchMedia("(display-mode: standalone)");

  const isInstalled = ref(isInstalledMediaQuery.matches);

  isInstalledMediaQuery.addEventListener("change", (event) => {
    isInstalled.value = event.matches;
  });

  const canInstall = computed(() => deferredPromptEvent.value !== undefined);

  async function showInstallPrompt() {
    if (!deferredPromptEvent.value) {
      return;
    }

    await deferredPromptEvent.value.prompt();
    deferredPromptEvent.value = undefined;
  }

  return {
    isInstalled,
    canInstall,
    showInstallPrompt,
  };
}
