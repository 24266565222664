<script setup lang="ts">
import { IonAlert } from "@ionic/vue";
import { useRegisterSW } from "virtual:pwa-register/vue";

const CHECK_UPDATE_INTERVAL = 60 * 60 * 1000;

const { needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegisteredSW(swUrl, registration) {
    if (!registration) {
      return;
    }

    const update = async () => {
      if (registration.installing || !navigator.onLine) {
        return;
      }

      const response = await fetch(swUrl, {
        cache: "no-store",
        headers: {
          "Cache-Control": "no-cache, no-store",
        },
      });

      if (response.ok) {
        await registration.update();
      }
    };

    setInterval(() => update(), CHECK_UPDATE_INTERVAL);
  },
});
</script>

<template>
  <IonAlert
    header="New version available"
    message="A new version of the app is available. Do you want to reload now?"
    :is-open="needRefresh"
    :buttons="[
      {
        text: 'Later',
        role: 'cancel',
        handler: () => (needRefresh = false),
      },
      {
        text: 'Reload',
        handler: () => updateServiceWorker(),
      },
    ]"
  />
</template>
