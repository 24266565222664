import { createTransformer } from "@packages/api";
import camelcaseKeys, { type Options } from "camelcase-keys";

function isObject(value: unknown): value is Record<PropertyKey, unknown> {
  return typeof value === "object" && value !== null;
}

export function camelCaseTransformer(options?: Options) {
  return createTransformer((response) => {
    return isObject(response)
      ? camelcaseKeys(response, { deep: true, ...options })
      : response;
  });
}
