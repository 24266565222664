import decamelizeKeys from "decamelize-keys";

import { createMiddleware } from ".";

type Options = Parameters<typeof decamelizeKeys>[1];

export function snakeCaseRequestMiddleware(options?: Options) {
  return createMiddleware((next) => (url, opts) => {
    opts = {
      ...opts,
      body: opts.body
        ? JSON.stringify(
            decamelizeKeys(JSON.parse(opts.body), { deep: true, ...options }),
          )
        : undefined,
    };

    return next(url, opts);
  });
}
