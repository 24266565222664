import { CancellablePromise, Cancellation } from "real-cancellable-promise";
import wretch, { type ConfiguredMiddleware } from "wretch";
import { abortAddon, queryStringAddon } from "wretch/addons";
import { WretchError } from "wretch/resolver";

import { createRequestError, createResponseError } from "./errors";
import { type Transformer, transformResponse } from "./transformers";
import { isJsonResponse } from "./utils";

type ApiConfig = {
  baseUrl: string;
  headers: HeadersInit;
  middlewares: ConfiguredMiddleware[];
  transformers: Transformer[];
  timeout: number;
};

type Schema<T> = {
  parse: (data: unknown) => T;
};

type RequestOptions<T> = {
  headers: HeadersInit;
  query: object | string;
  body: unknown;
  transformers: Transformer[];
  schema: Schema<T> | undefined;
  timeout: number;
};

export function createApi(config?: Partial<ApiConfig>) {
  const apiConfig: ApiConfig = {
    baseUrl: "",
    headers: {},
    middlewares: [],
    transformers: [],
    timeout: 30000,
    ...config,
  };

  const api = wretch(apiConfig.baseUrl)
    .addon(abortAddon())
    .addon(queryStringAddon)
    .content("application/json")
    .errorType("json")
    .headers(apiConfig.headers)
    .middlewares(apiConfig.middlewares);

  function request<T>(
    method: string,
    url: string,
    options?: Partial<RequestOptions<T>>,
  ) {
    const requestOptions: RequestOptions<T> = {
      query: {},
      headers: {},
      body: undefined,
      transformers: [],
      schema: undefined,
      timeout: apiConfig.timeout,
      ...options,
    };

    const controller = new AbortController();

    const promise = api
      .query(requestOptions.query)
      .headers(requestOptions.headers)
      .signal(controller)
      .resolve((resolver) => {
        return resolver.setTimeout(requestOptions.timeout, controller);
      })
      .catcherFallback((error) => {
        const { aborted, reason } = controller.signal;

        if (aborted && reason instanceof Cancellation) {
          throw reason;
        }

        if (error instanceof WretchError) {
          throw createResponseError(error);
        }

        throw createRequestError(error);
      })
      .fetch(method, url, requestOptions.body)
      .res(async (response) => {
        if (isJsonResponse(response)) {
          const json = transformResponse(await response.json(), [
            ...apiConfig.transformers,
            ...requestOptions.transformers,
          ]);

          if (requestOptions.schema) {
            return requestOptions.schema.parse(json);
          }

          return json;
        }

        return response.blob();
      }) as Promise<T>;

    return new CancellablePromise(promise, () => {
      controller.abort(new Cancellation());
    });
  }

  return {
    options<T>(
      url: string,
      options?: Partial<Omit<RequestOptions<T>, "body">>,
    ) {
      return request<T>("OPTIONS", url, options);
    },
    head<T>(url: string, options?: Partial<Omit<RequestOptions<T>, "body">>) {
      return request<T>("HEAD", url, options);
    },
    get<T>(url: string, options?: Partial<Omit<RequestOptions<T>, "body">>) {
      return request<T>("GET", url, options);
    },
    post<T>(url: string, options?: Partial<RequestOptions<T>>) {
      return request<T>("POST", url, options);
    },
    put<T>(url: string, options?: Partial<RequestOptions<T>>) {
      return request<T>("PUT", url, options);
    },
    patch<T>(url: string, options?: Partial<RequestOptions<T>>) {
      return request<T>("PATCH", url, options);
    },
    delete<T>(url: string, options?: Partial<Omit<RequestOptions<T>, "body">>) {
      return request<T>("DELETE", url, options);
    },
  };
}
