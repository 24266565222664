import { acceptHMRUpdate, defineStore } from "pinia";
import { reactive, toRefs } from "vue";

import { ProductsService } from "@/services";
import type { Product } from "@/types";

type State = {
  products: Product[];
};

export const useProductsStore = defineStore("products", () => {
  const state = reactive<State>({
    products: [],
  });

  async function load() {
    state.products = await ProductsService.getLibrary();
  }

  function get(id: Product["id"]) {
    return state.products.find((product) => product.id === id);
  }

  return {
    ...toRefs(state),
    load,
    get,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProductsStore, import.meta.hot));
}
