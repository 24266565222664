<script setup lang="ts">
import { IonApp, IonRouterOutlet } from "@ionic/vue";

import ReloadPrompt from "@/components/ReloadPrompt.vue";
import { useProductsStore, useUserStore } from "@/stores";

const userStore = useUserStore();
const productsStore = useProductsStore();

if (userStore.isAuthenticated) {
  productsStore.load();
}
</script>

<template>
  <IonApp>
    <IonRouterOutlet />
    <ReloadPrompt />
  </IonApp>
</template>
