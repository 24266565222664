import { z } from "zod";

import { api } from "@/api";
import { type Product, productSchema } from "@/types";

export function getById(id: string) {
  return api.get<Product>(`/v2/products/${id}`, {
    schema: z
      .object({ data: productSchema })
      .transform((response) => response.data),
  });
}

export function getLibrary() {
  return api.get<Product[]>("/v2/products/library", {
    schema: z
      .object({ data: z.array(productSchema) })
      .transform((response) => response.data),
  });
}
