import { createTransformer, replaceRecursive, type Transformer } from ".";

function isDateFormat(value: string) {
  return /^\d{4}-\d{2}-\d{2}T|\s?\d{2}:\d{2}:\d{2}(\.\d{3}\+\d{2}:\d{2})?$/.test(
    value,
  );
}

type PredicateFn = (key: string, value: string) => boolean;

/**
 * Creates a transformer that converts date strings to Date objects for
 * specified keys or based on a predicate function.
 *
 * @example
 * dateTransformer((key) => key.endsWith("At"))
 *
 * @example
 * dateTransformer(["createdAt", "updatedAt"])
 *
 * @example
 * dateTransformer<Message>(["createdAt", "willErrorBecauseThisKeyDoesNotExist"])
 */
export function dateTransformer(
  predicate: PredicateFn,
  isDateFn?: typeof isDateFormat,
): Transformer;

export function dateTransformer<T = never, K extends keyof T = keyof T>(
  keys: K[],
  isDateFn?: typeof isDateFormat,
): Transformer;

export function dateTransformer(
  predicateOrKeys: PredicateFn | string[],
  isDateFn = isDateFormat,
) {
  return createTransformer((response) => {
    return replaceRecursive(response, (key, value) => {
      if (
        typeof value === "string" &&
        (Array.isArray(predicateOrKeys)
          ? predicateOrKeys.includes(key)
          : predicateOrKeys(key, value)) &&
        isDateFn(value)
      ) {
        return new Date(value);
      }

      return value;
    });
  });
}
