export abstract class AbstractRequestError extends Error {}

export class FailedRequestError extends AbstractRequestError {
  constructor(message?: string) {
    super(message ?? "Failed Request Error");
  }
}

export function isRequestError(error: unknown): error is AbstractRequestError {
  return error instanceof AbstractRequestError;
}
