import { type AppConfig, type Plugin, warn } from "vue";

type ErrorHandler = NonNullable<AppConfig["errorHandler"]>;

/**
 * This is an approximation of Vue's default error handler, as it's
 * impossible to retain or invoke it when a custom error handler is set.
 *
 * Issue: {@link https://github.com/vuejs/vue/issues/8433}
 */
const logErrorHandler: ErrorHandler = (error, instance, info) => {
  if (import.meta.env.DEV) {
    if (info) {
      warn(`Unhandled error during execution of ${info}`);
    } else {
      warn("Unhandled error");
    }
  }

  console.error(error);
};

/**
 * Vue doesn't propagate uncaught exceptions to global error handlers in production builds.
 * Therefore, we need to define a custom Vue error handler for the app
 * in addition to listening to the `error` and `unhandledrejection` events.
 *
 * Issue: {@link https://github.com/vuejs/core/issues/7874}
 */
export function createErrorHandler(errorHandler: ErrorHandler): Plugin {
  return {
    install(app) {
      const originalErrorHandler = app.config.errorHandler;

      app.config.errorHandler = (error, instance, info) => {
        // If an error handler is defined (such as via Sentry),
        // we rely on it to log the error to the console for us.
        // If not, we log it ourselves using `logErrorHandler`.
        if (originalErrorHandler) {
          originalErrorHandler(error, instance, info);
        } else {
          logErrorHandler(error, instance, info);
        }

        errorHandler(error, instance, info);
      };

      window.addEventListener("error", ({ error, type }) => {
        errorHandler(error, null, type);
      });

      window.addEventListener("unhandledrejection", ({ reason, type }) => {
        errorHandler(reason, null, type);
      });
    },
  };
}
