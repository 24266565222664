export * from "./date.transformer";
export * from "./null-to-undefined.transformer";

export type Transformer<T = unknown> = (response: T) => unknown;

/**
 * Creates a transformer function.
 */
export function createTransformer<T>(transformerFn: Transformer<T>) {
  return transformerFn;
}

/**
 * Transforms the JSON object using the provided transformer functions.
 */
export function transformResponse(
  response: unknown,
  transformerFns: Transformer[],
) {
  return transformerFns.reduce(
    (value, transformerFn) => transformerFn(value),
    response,
  );
}

/**
 * Walks every key-value pair in the JSON object and replaces the value with the
 * result of the replacer function.
 */
export function replaceRecursive(
  response: unknown,
  replacerFn: (key: string, value: unknown) => unknown,
  parentKey = "",
): unknown {
  if (Array.isArray(response)) {
    return response.map((item) =>
      replaceRecursive(item, replacerFn, parentKey),
    );
  }

  if (typeof response === "object" && response !== null) {
    const transformedEntries = Object.entries(response).map(([key, val]) => {
      return [key, replaceRecursive(val, replacerFn, key)];
    });

    return Object.fromEntries(transformedEntries);
  }

  return replacerFn(parentKey, response);
}
