import { type WretchError } from "wretch";

import { HttpStatus } from "../";
import {
  type AbstractRequestError,
  type AbstractResponseError,
  FailedRequestError,
  UnauthorizedResponseError,
  UnexpectedResponseError,
  ValidationFailedResponseError,
} from ".";

function getErrorMessage(error: WretchError) {
  const message = error.json?.message;

  if (typeof message === "string" && message !== "") {
    return message;
  }
}

export function createResponseError(error: WretchError): AbstractResponseError {
  const message = getErrorMessage(error);

  if (error.status === HttpStatus.UNAUTHORIZED) {
    return new UnauthorizedResponseError(message);
  }

  if (error.status === HttpStatus.UNPROCESSABLE_ENTITY) {
    return new ValidationFailedResponseError(message);
  }

  return new UnexpectedResponseError(message);
}

export function createRequestError(error: Error): AbstractRequestError {
  return new FailedRequestError(error.message);
}
