import { createMiddleware } from "@packages/api";

import { useUserStore } from "@/stores";

export const authMiddleware = createMiddleware((next) => (url, options) => {
  const headers = new Headers(options.headers);

  const { authToken } = useUserStore();

  if (authToken) {
    headers.set("X-Auth-Token", authToken);
  }

  return next(url, { ...options, headers });
});
