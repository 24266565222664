import { isUnauthorizedResponseError } from "@packages/api";
import { resetStores } from "@packages/pinia-reset-stores";
import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";

import { useToasts } from "@/composables";
import { UserService } from "@/services";
import { useProductsStore } from "@/stores";
import type { User } from "@/types";

type State = {
  authToken: string | undefined;
  user: User | undefined;
};

export const useUserStore = defineStore(
  "user",
  () => {
    const state = reactive<State>({
      authToken: undefined,
      user: undefined,
    });

    const isAuthenticated = computed(() => {
      return state.authToken !== undefined;
    });

    const fullName = computed(() =>
      state.user
        ? [state.user.firstName, state.user.lastName].join(" ")
        : undefined,
    );

    const toasts = useToasts();
    const productsStore = useProductsStore();

    async function login(email: string, password: string) {
      try {
        const { authToken, user } = await UserService.login(email, password);

        state.authToken = authToken;
        state.user = user;

        productsStore.load();
      } catch (error) {
        if (isUnauthorizedResponseError(error)) {
          toasts.addError(error.message);
          return;
        }

        throw error;
      }
    }

    async function logout() {
      try {
        await resetStores();
      } catch (error) {
        console.warn(error);
      }
    }

    async function resetPassword(email: string) {
      await UserService.resetPassword(email);
    }

    function addAuthTokenToUrl(inputUrl: string) {
      if (!state.authToken) {
        throw new Error("No auth token available");
      }

      const url = new URL(inputUrl);

      url.searchParams.set("_token", state.authToken);

      return url.toString();
    }

    return {
      ...toRefs(state),
      isAuthenticated,
      fullName,
      login,
      logout,
      resetPassword,
      addAuthTokenToUrl,
    };
  },
  {
    persist: {
      paths: ["authToken", "user"],
    },
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
